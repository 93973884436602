import store from '@/store/index'
import moment from 'moment'
const AUTHKEY = "app_jwt"
const PROFILE_KEY = "profile_key"
const LOGIN_CREDENTIALS = 'LOGIN_CREDENTIALS'


export const init = () => {
    store.state.logged = !!isLogged()
    store.state.user = getProfile()
}


export const login = (token, user) => {
    token.ends = moment().add(token.expires_in,'seconds')
    window.localStorage.setItem(AUTHKEY, JSON.stringify(token))
    store.commit('saveUser', { user: user, vm: null })
    // store.state.logged = true
    // store.state.user = null
}


export const updateProfile = (profile) => {
    store.commit('saveUser', { user: profile, vm: null })
    // store.state.user = profile
    window.localStorage.setItem(PROFILE_KEY, JSON.stringify(profile))
}


export const getProfile = () => {
    let p = window.localStorage.getItem(PROFILE_KEY)
    if(p) {
        return JSON.parse(p)
    }
    return null
}


export const isLogged = () => {
    return localStorage.getItem(AUTHKEY)
}


export const logout = () => {
    store.commit('saveUser', { user: null, vm: null })
    // store.state.logged = false
    // store.state.user = null
    window.localStorage.removeItem(AUTHKEY)
    window.localStorage.removeItem(PROFILE_KEY)
}

export const getLoginCredentials = () => {
    const credentials = window.localStorage.getItem(LOGIN_CREDENTIALS)
    if(!credentials) return null
    try {
        return JSON.parse(atob(credentials))
    } catch (error) {
        return null
    }

}

export const setLoginCredentials = (value) => {
    window.localStorage.setItem(LOGIN_CREDENTIALS, btoa(JSON.stringify(value)))
}

export default { init, login, isLogged, logout, updateProfile, getProfile };
