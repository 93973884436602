import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from './router'
import VueMeta from 'vue-meta'
import Default from '@/views/layouts/Default'
import Empty from '@/views/layouts/Empty'
import Loading from '@/views/layouts/Loading'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import ApiService from "@/services/api.service"
import AuthService from "@/services/auth.helper"
import LangHelper from "@/services/lang.helper"
import VueSweetalert2 from 'vue-sweetalert2'
import {DictionaryService} from '@/services/api.service'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueCompositionAPI from '@vue/composition-api'
// import VueIdentifyNetwork from 'vue-identify-network';
// import VueTagManager from "vue-tag-manager"
// import * as Sentry from "@sentry/browser";
// import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from "@sentry/tracing";

let lang = LangHelper.getLanguage()
Vue.component('default-layout', Default)
Vue.component('empty-layout', Empty)
Vue.component('loading-layout', Loading)
Vue.use(VueI18n)
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2)
Vue.use(VueCompositionAPI)
Vue.use(VueAwesomeSwiper);
// Vue.use(VueIdentifyNetwork)

// if(process.env.NODE_ENV == 'production') {
//     // Sentry Inits
//     Sentry.init({
//         dsn: "https://6d296155e2144bd195bcde8b320c7613@o185973.ingest.sentry.io/5502586",
//         integrations: [
//             new VueIntegration({
//                 Vue,
//                 tracing: true,
//             }),
//             new Integrations.BrowserTracing(),
//         ],
//
//         // We recommend adjusting this value in production, or using tracesSampler for finer control
//         tracesSampleRate: 1.0,
//     });
// }

// Vue.use(VueTagManager, {
//     gtmId: 'GTM-PBX2F2H'
// })
import ZoomOnHover from "vue-zoom-on-hover"
Vue.use(ZoomOnHover)

import VueInputAutowidth from 'vue-input-autowidth'
Vue.use(VueInputAutowidth)

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

// import VueDraggableResizable from 'vue-draggable-resizable'
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
// Vue.component('vue-draggable-resizable', VueDraggableResizable)

import VueImg from 'v-img'
Vue.use(VueImg)

const moment = require('moment-timezone')
if (lang && lang == 'undefined') {
    lang = 'en'
}
if(lang == 'kz') lang = 'kk'
// if (lang && !['en', 'undefined'].includes(lang)) {
//     require('moment/locale/' + lang)
// }
require('moment/locale/kk')
require('moment/locale/ru')
moment.locale(lang)
Vue.use(require('vue-moment-tz'), { moment })

ApiService.init(router)
AuthService.init()

Vue.config.productionTip = false

Vue.prototype.t = (object, parameter) => {
    let p = parameter + window.lang
    if (!object) {
        return ""
    }
    if (object[p]) {
        return object[p]
    }
    if (object[parameter]) {
        return object[parameter]
    }
    return ""
}

Vue.prototype.roleLinkAppendix = () => {
    if(!store.state.user) {
        return ''
    }
    let role = store.state.user.primary_role_id
    switch (role) {
        case 1:
            return '/instructor'
        case 2:
            return '/student'
        case 3:
            return '/instructor'
        case 4:
            return '/school'
        case 5:
            return '/school'
        case 6:
            return '/instructor'
        default:
            return ''
    }
}

Vue.prototype.getListNumbers = (start, stop, step) => {
    if (!step) {
        step = 1
    }
    return new Array((stop - start) / step + 1).fill(start).map((n, i) => n + i * step)
}

Vue.prototype.appendFormdata = (FormData, data, name) => {
    name = name || '';
    if (typeof data === 'object') {
        for (var index in data) {
            if (Object.prototype.hasOwnProperty.call(data, index)) {
                if (name == '') {
                    Vue.prototype.appendFormdata(FormData, data[index], index)
                } else {
                    Vue.prototype.appendFormdata(FormData, data[index], name + '[' + index + ']')
                }
            }
        }
    } else {
        FormData.append(name, data)
    }
    return FormData
}

Vue.prototype.apiResponse = (object) => {
    if (!object || !object.data) {
        return {success: false, data: "error"}
    }
    if (object.data.success) {
        return {success: true, data: object.data.data}
    }
    return {success: false, data: object.data.message}
}

Vue.prototype.errResponse = (err) => {
    if (err.response.data.message) {
        Vue.prototype.$error(err.response.data.message)
    }
}


Vue.filter("formatNumber", function (value) {
    return new Intl.NumberFormat('ru-RU').format(value)
})

Vue.filter("timeFromSeconds", function (value) {
    return moment.utc((parseInt(value) * 1000)).format('HH:mm')
})

Vue.filter("momentTz", function (value, format = 'DD.MM.YYYY, HH:mm') {
    return moment.utc(value).tz(store.state?.user?.timezone || 'UTC').format(format)
})

Vue.prototype.enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = []
    let currDate = moment(startDate).startOf('day')
    let lastDate = moment(endDate).startOf('day')
    dates.push(currDate.toDate())
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate())
    }
    return dates
}

import clickOutside from '@/directives/v-click-outside'

Vue.directive('click-outside', clickOutside)

import messages from '@/lang/lang'

const i18n = new VueI18n({
    locale: lang,
    messages,
})

import {Notifications} from "@/plugins/notifications"

Vue.use(Notifications, messages, lang)

let initialLang = null

const load = () => {
    new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
    if(initialLang && i18n.locale != initialLang) {
        i18n.locale = initialLang
    }
}


DictionaryService.initialLoad().then(res => {
    let d = res.data.data
    if (d.user) {
        store.commit('saveUser', {user: d.user, vm: null})
        store.dispatch('syncTimeZone')
        if(d.user.school && d.user.school.school_lang) {
            initialLang = d.user.school.school_lang
        } else if(d.user.interface_lang) {
            initialLang = d.user.interface_lang
        }
    }
    if (d.dictionary) {
        store.commit('saveDictionary', d.dictionary)
    }
    load()
}).catch(() => {
    load()
})




