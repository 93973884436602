<template>
    <div class="cover"></div>
</template>

<script>
    export default {
        name: "cover"
    }
</script>

<style lang="scss" scoped>
    .cover {
        position: absolute;
        z-index: 99;
        width: 100%;
        top: 0;
        bottom: 0;
        background: black;
        opacity: 0.5;
    }
</style>
