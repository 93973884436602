<template>
  <div class="text-center">
    <div class="is-typing">
      <div class="jump1"></div>
      <div class="jump2"></div>
      <div class="jump3"></div>
      <div class="jump4"></div>
      <div class="jump5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatLoader"
}
</script>

<style scoped>
body {
  background-color: #000;
}

.is-typing {
  margin: 20px auto;
  width: 85px;
  justify-content: space-around;
  display: flex;
}

.jump1,
.jump2,
.jump3,
.jump4,
.jump5 {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: gray;
}

.jump1 {
  animation: typing 1.0s linear infinite;
  animation-delay: 0.6s;
}

.jump2 {
  animation: typing 1.0s linear infinite;
  animation-delay: 0.7s;
}

.jump3 {
  animation: typing 1.0s linear infinite;
  animation-delay: 0.8s;
}

.jump4 {
  animation: typing 1.0s linear infinite;
  animation-delay: 0.9s;
}

.jump5 {
  animation: typing 1.0s linear infinite;
  animation-delay: 1.0s;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(0px);
  }

  35% {
    transform: translateY(10px);
  }

  45% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(-10px);
  }

  75% {
    background-color: white;
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}
</style>
