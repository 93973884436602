<template>
  <div>
    <header class="header">
      <div class="container mob-container h-100">
        <div class="row h-100 align-items-center justify-content-between">
          <div class="col-md-auto col-4">
            <router-link :to="'/'+role+'/home'">
              <img src="/images/logo_h.svg" class="header-img-logo" alt="logo"/>
            </router-link>
          </div>
          <div class="col-md-auto h-100 header-menu d-md-block d-none">
            <router-link class="header-item"
                         v-for="(m, mind) in menu"
                         :to="m.url"
                         :key="'sidebar-item-'+mind">
              {{ $t(m.title) }}
            </router-link>
          </div>
          <div class="d-block d-md-none font-weight-bold text-center col-4">
            {{ pageMobileTitle }}
          </div>
          <div class="d-block d-md-none col-4">
            <a @click="mobMenu" class="d-block position-relative h-100 mob-menu-toggler">
              <transition name="fade">
                <MenuSvg class="svg-themed" v-if="!mobileopened"/>
              </transition>
              <transition name="fade">
                <CloseSvg v-if="mobileopened"/>
              </transition>
            </a>
          </div>
          <div class="col-md-auto d-md-block d-none">
            <HomeworkMenuIcon v-if="role === 'student'" class="mr-3"/>
            <router-link v-if="role === 'student'" :to="`/${role}/referral`" class="light-btn">
              <GiftSvg/>
            </router-link>
            <a v-if="role === 'student' && defaultBot"
               class="light-btn pointer ml-3"
               @click="showBot = !showBot">
              <ChatSvg class="svg-blue"/>
            </a>
            <!--                    <a class="header-icon circle-coin black-fill">-->
            <!--                        <SearchSvg/>-->
            <!--                    </a>-->
            <!--                    <a class="header-icon circle-coin">-->
            <!--                        <BellSvg/>-->
            <!--                    </a>-->
            <!--                    <div class="header-separator"></div>-->
            <b-dropdown right no-caret menu-class="noscrollbar"
                        variant="link"
                        id="header-profile-dropdown"
                        class="d-inline-block pointer">
              <template v-slot:button-content>
                <a class="header-icon circle-coin">
                  <LetterIcon :obj="$store.state.user"
                              :title="$store.state.user ? $store.state.user.name : '?'"
                              class="student-icon sm"/>
                </a>
                <VertDotsSvg  class="ml-2 d-inline-block"/>
              </template>
              <b-dropdown-item class="border-bottom">
                <span class="sp-bolder d-inline-block dropdown-name">{{ $store.state.user ? $store.state.user.name : '' }}</span>
              </b-dropdown-item>
              <b-dropdown-item :to="roleLinkAppendix()+'/settings/profile'">
                {{ $t('profile') }}
              </b-dropdown-item>
              <b-dropdown-item @click="logout">
                {{ $t('logout') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </header>
    <div class="mobile-menu d-md-none bg-white" :class="{ opened: mobileopened }">
      <div class="py-3">
        <router-link class="header-item"
                     v-for="(m, mind) in menu"
                     :to="m.url"
                     :key="'sidebar-item-'+mind">
          {{ $t(m.title) }}
        </router-link>
        <router-link class="header-item"
                     v-if="role === 'student'"
                     :to="`/${role}/referral`">
          {{ $t('referral_system') }}
        </router-link>
        <!--                <router-link class="header-item" :to="roleLinkAppendix()+'/settings/profile'">-->
        <!--                    {{ $t('profile') }}-->
        <!--                </router-link>-->
      </div>
    </div>
    <transition name="fade">
      <div v-if="mobileopened" class="header-blur" @click="mobileopened = false"></div>
    </transition>
    <AIChat :bot="defaultBot"
            v-if="defaultBot && showBot && role === 'student'"
            @close="showBot = false"/>
  </div>
</template>

<script>
import VertDotsSvg from "@/assets/svg/vertdots.svg"
import GiftSvg from "@/assets/svg/gift.svg"
// import LogoSvg from "@/assets/svg/logo.svg"
import MenuSvg from "@/assets/svg/menu.svg"
import CloseSvg from "@/assets/svg/closemenu.svg"
import ChatSvg from "@/assets/svg/chat.svg"
// import SearchSvg from "@/assets/svg/search.svg"
// import BellSvg from "@/assets/svg/bell.svg"
import AuthService from "@/services/auth.helper"
import LetterIcon from '@/views/parts/general/LetterIcon'
import EventBus from '@/services/event-bus'
import AIChat from "../specific/AIChat.vue"
// import Breadcrumbs from "@/views/parts/general/Breadcrumbs"
import HomeworkMenuIcon from "../specific/HomeworkMenuIcon.vue"

export default {
  components: {
    AIChat,
    // LogoSvg,
    GiftSvg,
    MenuSvg,
    CloseSvg,
    ChatSvg,
    // SearchSvg,
    // BellSvg,
    VertDotsSvg,
    LetterIcon,
    // Breadcrumbs,
    HomeworkMenuIcon
  },
  methods: {
    logout() {
      this.$confirm(this.$t('are_you_sure_you_want_to_logout')).then(() => {
        AuthService.logout()
        this.$router.push('/login')
      })
    },
    mobMenu() {
      this.mobileopened = !this.mobileopened
    }
  },
  data() {
    return {
      langs: ['ru','kz'],
      instructor_menu: [
        // { url: "/instructor/home", title: "home" },
        { url: "/instructor/schedule", title: "schedule" },
        { url: "/instructor/courses", title: "materials" },
        { url: "/instructor/lightcourses", title: "light" },
        { url: "/instructor/students", title: "students" },
        { url: "/instructor/examination", title: "examination" },
        { url: "/instructor/payments", title: "payments" },
        { url: "/instructor/settings", title: "settings" },
      ],
      school_menu: [
        // { url: "/school/home", title: "home" },
        { url: "/school/schedule", title: "schedule" },
        { url: "/school/courses", title: "materials" },
        { url: "/school/lightcourses", title: "light" },
        { url: "/school/teachers", title: "staff" },
        { url: "/school/students", title: "students" },
        { url: "/school/payments", title: "payments" },
        { url: "/school/settings", title: "settings" },
      ],
      manager_menu: [
        // { url: "/manager", title: "home" },
        { url: "/school/schedule", title: "schedule" },
        { url: "/school/courses", title: "materials" },
        { url: "/school/lightcourses", title: "light" },
        { url: "/school/teachers", title: "staff" },
        { url: "/school/students", title: "students" },
        // { url: "/school/payments", title: "payments" },
        { url: "/school/settings", title: "settings" },
      ],
      methodist_menu: [
        { url: "/instructor/courses", title: "materials" },
        { url: "/instructor/lightcourses", title: "light" },
        { url: "/instructor/settings", title: "settings" },
      ],
      student_menu: [
        // { url: "/student/home", title: "home" },
        { url: "/student/vocabulary", title: "vocabulary" },
        { url: "/student/courses", title: "courses" },
        { url: "/student/chat/history", title: "chat_history" },
        { url: "/student/settings", title: "settings" },
      ],
      mobileopened: false,
      showBot: false
    }
  },
  mounted() {
    EventBus.$on('menuclose',() => {
      this.mobileopened = false
    })
  },
  computed: {
    role() {
      return this.$store.getters.roleName
    },
    pageMobileTitle() {
      return this.$store.state.pageMobileTitle
    },
    defaultBot() {
      return this.$store.state.dictionary?.default_bot
    },
    menu() {
      if(!this.$store.state.user) {
        return this.student_menu
      }
      if(this.role === 'instructor') {
        let m = this.instructor_menu
        if(!this.$store.state.user.can_admin_payments) {
          m = this.instructor_menu.filter(function( obj ) {
            return obj.url !== "/instructor/payments"
          })
        }
        return m
      }
      if(this.role === 'school') {
        return this.school_menu
      }
      if(this.role === 'manager') {
        return this.manager_menu
      }
      if(this.role === 'methodist') {
        return this.methodist_menu
      }
      if(this.role === 'student') {
        return this.student_menu
      }
      return this.student_menu
    },
  }
};
</script>

<style scoped lang="scss">
.dropdown-name {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
