const state = {
    card: null,
    componentToCopy: null
}

const getters = {

}

const mutations = {
    setCard(state, value) {
        state.card = value
    },
    setComponentToCopy(state, value) {
        state.componentToCopy = value
    }
}

const actions = {
};

export default {
    state,
    actions,
    mutations,
    getters
}
