<template>
    <footer id="footer" class="footer">
        <div class="container">
        </div>
    </footer>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
        }
    }
</script>