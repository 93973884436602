export default function student({ next, router }) {

    if(router.app.$store && router.app.$store.state.logged && router.app.$store.state.user) {
        let role_id = parseInt(router.app.$store.state.user.primary_role_id)
        if([2].includes(role_id)) {
            return next()
        }
    }

    router.push('/login')
}