<template>
  <vue-resizable :left="isMobile ? 0 : 72"
                 :width="isMobile ? windowW : 400"
                 :disableAttributes="[]"
                 :minWidth="300"
                 :minHeight="200"
                 :height="isMobile ? (windowH - 64) : 450"
                 :active="['r','b', 'l', 't', 'lb', 'rb', 'lt', 'rt']"
                 class="chat-window"
                 dragSelector=".drag-part">
    <div class="spacecard h-100">
      <div class="chat-wrapper">
        <div class="drag-part">
          <b-row class="align-items-center">
            <b-col>
              <h3 class="mb-0">{{ $t('chat') }}</h3>
            </b-col>
            <b-col cols="auto">
              <a class="pointer position-relative modal-close call_room_btn"
                 @click="$emit('close')">
                <CloseSvg/>
              </a>
            </b-col>
          </b-row>
        </div>
        <hr/>
        <div class="d-flex flex-column justify-content-between height-calc">
          <div class="chat-card h-100 d-flex flex-column justify-content-between overflow-hidden">
            <div class="messages-wrapper noscrollbar h-100"
                 ref="messagewrapper"
                 @scroll="handleScroll">
              <div v-for="(message, mind) in messages"
                   :key="'message-'+mind"
                   :ref="'message-'+message.id"
                   class="message"
                   :class="{ mine: !message.bot_generated }">
                <b-row class="align-items-end no-gutters">
                  <!--                  <b-col cols="auto">-->
                  <!--                    <div class="user-icon-wrap">-->
                  <!--                      {{ message.bot_generated ? 'BOT' : user.name }}-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <b-col>
                    <div class="message__content position-relative">
                      <div class="message__username">
                        {{ message.bot_generated ? 'BOT' : user.name }}
                      </div>
                      <div>
                        <div class="message__message">{{ message.message }}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="message__time">{{ message.local_date | moment('HH:mm') }}</div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div v-if="botResponding" class="message">
                <div class="message__content position-relative">
                  <div class="message__message">
                    <div>{{ streamMessage }}</div>
                    <ChatLoader/>
                  </div>
                </div>
              </div>

            </div>

            <div class="pt-2 p-1">
              <div class="position-relative">
                <AutosizeTextarea class="message-textarea"
                                  @enter="enterPressed"
                                  :standardEnter="false"
                                  :placeholder="$t('message')"
                                  v-model="messageText"/>
                <b-button @click="send"
                          :disabled="loading || botResponding"
                          class="send-message pr-0">
                  <SendSvg/>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-resizable>
</template>

<script>
import { mapState } from "vuex"
import VueResizable from 'vue-resizable'
import CloseSvg from '@/assets/svg/close_gray.svg'
import SendSvg from "@/assets/svg/send.svg"
import AutosizeTextarea from "@/views/parts/general/form/AutosizeTextArea"
import { ChatService } from "../../../services/api.service"
import ChatLoader from "../general/ChatLoader.vue"
import TokenHelper from "../../../services/token.helper"

export default {
  name: "AIChat",
  components: {
    ChatLoader,
    VueResizable,
    CloseSvg,
    SendSvg,
    AutosizeTextarea,
    // LetterIcon
  },
  data() {
    return {
      messageText: '',
      messages: [],
      no_more_messages: false,
      loading: false,
      botResponding: false,
      streamMessage: '',
      startingPoint: null,
      responseType: 'full' // stream , full
    }
  },
  props: {
    bot: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile,
      user: state => state.user,
    }),
    windowW() {
      return window.innerWidth
    },
    windowH() {
      return window.innerHeight
    },
  },
  methods: {
    async send() {
      if (!this.messageText) return
      this.loading = true
      const { data: { data: userMessage } } = await ChatService.sendBotMessage(this.bot.id, {
        message: this.messageText
      })
      if(!userMessage?.id) return
      if(!this.startingPoint) this.startingPoint = userMessage.id
      this.messages.push(userMessage)
      this.messageText = ""
      this.botResponding = true
      this.scrollBottom()
      this.streamMessage = ''

      if(this.responseType === 'stream') {
        const source = new EventSource(`${process.env.VUE_APP_API_URL}/shared/chat_bots/${this.bot.id}/messages/${userMessage.id}/response?access_token=${TokenHelper.getAccessToken().access_token}&startingPoint=${this.startingPoint}`);

        source.onmessage = (payload) => {
          // console.log('source message')
          // console.log(payload)
          if(payload.data === '[DONE]') {
            cancelSource(true)
            return
          }
          const parsed = JSON.parse(payload.data)
          const chunk = parsed?.choices?.[0]?.delta?.content || ''
          this.streamMessage += chunk
        }

        source.onerror = (error) => {
          console.log('error')
          console.log(error)
          cancelSource()
        }

        const cancelSource = async (success = false) => {
          source.close()
          this.scrollBottom()
          if(success) {
            try {
              const { data: { data: botMessage } } = await ChatService.recordBotMessage(this.bot.id, { message: this.streamMessage })
              this.messages.push(botMessage)
            } catch (e) {
              console.log(e.toString())
            }
          }
          this.loading = false
          this.botResponding = false
          this.streamMessage = ''
        }
      }

      if(this.responseType === 'full') {
        const { data: { data: botMessage } } = await ChatService.getBotResponse(this.bot.id, userMessage.id, { startingPoint: this.startingPoint })
        this.messages.push(botMessage)
        this.scrollBottom()
        this.loading = false
        this.botResponding = false
      }


    },
    handleScroll(el) {
      if(el.target.scrollTop < 1 && !this.loading) { // scrolled to top
        this.getMessages(this.messages?.[0]?.id || '')
      }
    },
    scrollBottom() {
      setTimeout(() => {
        if(this.$refs.messagewrapper) this.$refs.messagewrapper.scrollTop = this.$refs.messagewrapper.scrollHeight
      }, 50)
    },
    enterPressed() {
      this.send()
    },
    async getMessages(oldest_message_id = '') {
      if(this.no_more_messages) return
      this.loading = true
      const { data: { data: messages } } = await ChatService.getBotMessages(this.bot.id, oldest_message_id)
      this.messages = messages.concat(this.messages)
      if (!messages.length) this.no_more_messages = true
      this.loading = false
      if(!oldest_message_id) this.scrollBottom()
    },
  },
  mounted() {
    this.getMessages()
  }
}
</script>

<style scoped lang="scss">
.chat-window {
  z-index: 9;
}
.chat-wrapper {
  height: 100%;
  .height-calc {
    height: calc(100% - 67px);
  }
  .chat-card {
    .chat-header {
      margin-bottom: 24px;
    }
    .messages-wrapper {
      overflow: scroll;
      .message {
        &:first-of-type {
          margin-top: 0;
        }
        margin-top: 16px;
        &__content {
          border-radius: 12px;
          border-bottom-left-radius: 0;
          border: 1px solid $border-gray;
          background: #fff;
          padding: 7px 25px 7px 9px;
          font-weight: 400;
          font-size: 14px;
          max-width: 80%;
          white-space: pre-wrap;
        }
        &__time {
          position: absolute;
          font-size: 10px;
          color: $text-gray3;
          right: 10px;
          bottom: 5px;
        }
        &__username {
          font-size: 12px;
          font-weight: 600;
          color: $text-gray3;
        }
        &__message {
          font-weight: 500;
          word-break: break-word;
        }
        &.mine {
          .message__content {
            background: $gray-back2;
            border: 1px solid $gray-back2;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 0;
            margin-left: 20%;
            .message__time {
              text-align: right;
            }
          }
        }
      }
    }
    .send-message {
      right: 0;
      top: 0;
      position: absolute;
      height: 100%;
      border: none;
      background: transparent;
      outline: none;
      svg {
        fill: $gray-fill;
      }
    }
    .message-textarea {
      width: calc(100%  - 36px);
      font-size: 15px;
      &:focus {
        background: $gray-back;
      }
    }
  }
}
</style>
