<template>
  <div>
    <!--        <sidebar/>-->
    <main :class="mainClass">
      <transition name="fade">
        <Cover v-if="videoModalOpen"/>
      </transition>
      <spaceheader/>
      <div class="container mob-container position-relative">
        <slot/>
      </div>
      <spacefooter/>
    </main>
    <div v-if="$store.state.loading" class="loader-wrapper">
      <Loader />
    </div>
  </div>
</template>
<script>
// import Sidebar from "./../parts/general/Sidebar"
import Spaceheader from "./../parts/general/Header"
import Spacefooter from "./../parts/general/Footer"
import Loader from '@/views/parts/general/Loader.vue'
import Cover from "../parts/specific/cover";
import { mapState } from 'vuex'

export default {
  components: {
    Spaceheader,
    Spacefooter,
    Cover,
    // Sidebar,
    Loader
  },
  beforeCreate() {
    document.body.classList.remove("empty")
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  computed: {
    ...mapState({
      videoModalOpen: state => state.videoModalOpen,
      mainClass: state => state.mainClass
    })
  }
}
</script>
