const Notifications = {

    install(Vue, messages, lang) {

        Vue.prototype.$toast = (instance, message = '', title = '👍') => {
            instance.$bvToast.toast(message, {
                title: title,
                autoHideDelay: 3000,
                toaster: "b-toaster-bottom-right",
        })
        }


        Vue.prototype.$confirm = (message) => {
            return new Promise(function(resolve) {
                Vue.swal({
                    title: message,
                    customClass: {
                        popup: "spaceduck-swal-confirm",
                        image:'swal-image-confirm',
                    },
                    imageUrl: '/images/emojis/sure.png',
                    showCancelButton: true,
                    cancelButtonText: messages[lang]['cancel'],
                    confirmButtonText: messages[lang]['yes'],
                    reverseButtons: true,
                }).then(res => {
                    if(res.isConfirmed) {
                        resolve(true)
                    }
                })
            })
        }


        Vue.prototype.$input = (message, placeholder, prefillvalue = "") => {
            return new Promise(function(resolve) {
                Vue.swal({
                    title: message,
                    customClass: {
                        popup: "spaceduck-swal-input",
                        input: "form-control form-control-white",
                        image:'swal-image-input',
                    },
                    showCancelButton: true,
                    input: 'text',
                    inputPlaceholder: placeholder,
                    inputValue: prefillvalue,
                    cancelButtonText: messages[lang]['cancel'],
                    confirmButtonText: messages[lang]['save'],
                    reverseButtons: true,
                }).then(res => {
                    if(res.isConfirmed) {
                        resolve(res.value)
                    }
                })
            })
        }


        Vue.prototype.$success = (message) => {
            Vue.swal({
                title: message,
                customClass: {
                    image:'swal-image-confirm',
                },
                imageUrl: '/images/emojis/right.png',
            })
        }

        Vue.prototype.$successPlain = (message) => {
            Vue.swal({
                title: message,
            })
        }

        Vue.prototype.$successtimer = (message) => {
            Vue.swal({
                title: message,
                customClass: {
                    image:'swal-image-confirm',
                },
                imageUrl: '/images/emojis/right.png',
                position: 'bottom-end',
                timer: 1300
            })
        }

        Vue.prototype.$error = (message) => {
            Vue.swal({
                title: message,
                customClass: {
                    image:'swal-image-confirm',
                },
                imageUrl: '/images/emojis/oops.png',
            })
        }


    }
}

export { Notifications }
