<template>
  <div class="letter-icon-wrapper">
    <img class="round-icon"
         v-if="obj && obj.image"
         :src="obj.image"
         onerror="this.src='/images/user.png'"/>
    <div v-else :class="`letter-icon-circle ${passedClass}`">
      {{ customTitle !== null ? customTitle : (title ? title.charAt(0) : '') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {},
    title: {},
    passedClass: {},
    customTitle: {
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped>
.student-card .round-icon {
  background-color: #fff;
}
</style>
