import Vue from 'vue'
import Vuex from 'vuex'
import LangHelper from "@/services/lang.helper"
import call from "@/store/call.store";
import material from "@/store/material.store";
import moment from 'moment-timezone'
import {DictionaryService, UsersService} from "../services/api.service"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    videoModalOpen: false,
    fullVideoOpen: false,
    logged: false,
    loading: false,
    user: null,
    dictionary: null,
    isMobile: false,
    isKeyboardOpen: false,
    specialkey: 1,
    userInvitationModalData: null,
    mainClass: null,
    pageMobileTitle: ''
  },
  getters:{
    getVideoModalOpen (state) {
      return state.videoModalOpen
    },
    getFullVideoOpen (state) {
      return state.fullVideoOpen
    },
    school (state) {
      return state.user ? state.user.school : null
    },
    schoolMetadata(state, getters) {
      return getters.school && getters.school.meta_data ? getters.school.meta_data : {}
    },
    roleName(state) {
      let role_id = state.user ? state.user.primary_role_id : ""
      if([1,3].includes(role_id)) {
        return 'instructor'
      }
      if([4].includes(role_id)) {
        return 'school'
      }
      if([2].includes(role_id)) {
        return 'student'
      }
      if([5].includes(role_id)) {
        return 'manager'
      }
      if([6].includes(role_id)) {
        return 'methodist'
      }
      return ""
    }
  },
  mutations: {
    setVideoModalOpen(state, videoModalOpen) {
      state.videoModalOpen = videoModalOpen
    },
    setKeyboardOpen(state, value) {
      state.isKeyboardOpen = value
    },
    setFullVideoOpen(state, fullVideoOpen) {
      state.fullVideoOpen = fullVideoOpen
    },
    setSpecialKey(state) {
      state.specialkey = Math.floor(Math.random() * 1000) + 1
    },
    setIsMobile(state, value) {
      state.isMobile = value
    },
    setPageMobileTitle(state, value) {
      state.pageMobileTitle = value
    },
    saveUser: (state, { user, vm }) => {
      state.user = user
      state.logged = !!user
      if(vm) {
        let lang = user.interface_lang
        vm.$i18n.locale = lang
        LangHelper.saveLanguage(lang)
        moment.locale(lang)
      }
      if(user && user.school && user.school.school_lang) {
        if(vm) vm.$i18n.locale = user.school.school_lang
        LangHelper.saveLanguage(user.school.school_lang)
      }
    },
    saveDictionary: (state, dictionary) => {
      state.dictionary = dictionary
    },
    setUserInvitationModalData(state, value) {
      state.userInvitationModalData = value
    },
    setSchool(state, school) {
      state.user.school = school
    }
  },
  actions: {
    syncTimeZone({state}) {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if(browserTimeZone && state.user?.timezone && state.user?.timezone !== browserTimeZone) {
        UsersService.update({ timezone: browserTimeZone })
      }
    },
    loadDictionary({ commit }) {
      DictionaryService.initialLoad().then(res => {
        let d = res?.data?.data
        if (d?.dictionary) {
          commit('saveDictionary', d.dictionary)
        }
      })
    }
  },
  modules: {
    call,
    material,
  }
})
