<template>
  <div>
    <slot/>
    <div v-if="$store.state.loading" class="loader-wrapper">
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@/views/parts/general/Loader.vue'

export default {
  components: {
    Loader
  },
  beforeCreate() {
    document.body.classList.add("empty")
  }
}
</script>
