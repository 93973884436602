export default function instructor({ next, router }) {
    if(router.app.$store && router.app.$store.state.logged && router.app.$store.state.user) {
        let role_id = parseInt(router.app.$store.state.user.primary_role_id) 
        if([1,3,4,5].includes(role_id)) {
            return next()
        }
        if([2].includes(role_id)) {
            router.push('/student/home')
            return
        }
        if([3].includes(role_id)) {
            router.push('/instructor/home')
            return
        }
    }
    router.push('/login')
}
