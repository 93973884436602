<template>
    <div class="vh-100 d-flex align-items-center justify-content-center">
        <Loader/>
    </div>
</template>

<script>
    import Loader from '@/views/parts/general/Loader.vue'

    export default {
        components: {
            Loader
        }
    }
</script>