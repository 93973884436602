const TOKEN_KEY = "app_cuba_token"
const AUTHKEY = "app_jwt"
import moment from 'moment'


export const getAccessToken = () => {
  var string = window.localStorage.getItem(AUTHKEY)
  if(!string) {
    string = window.localStorage.getItem(TOKEN_KEY)
  }
  if(string) {
    const obj = JSON.parse(string)
    // console.log(obj)
    let endTime = moment(obj.ends)
    let startTime = moment()
    let duration = moment.duration(endTime.diff(startTime)).asSeconds()
    if(duration > 1) {
      return obj
    }
  }
  return null
}


export const getRefreshToken = () => {
  const string = window.localStorage.getItem(TOKEN_KEY);
  if(string) {
    const obj = JSON.parse(string)
    return obj.refresh_token
  }
  return null
}


export const saveToken = token => {
  token.ends = moment().add(token.expires_in,'seconds')
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}


export const destroyToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(AUTHKEY);
}


export default { getAccessToken, saveToken, destroyToken, getRefreshToken };
