<template>
  <div>
    <textarea class="form-control showplaceholder"
              :class="{ 'form-control-white': white }"
              :placeholder="placeholder"
              :style="inputStyle"
              :value="value"
              @focus="focus"
              :disabled="disabled"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="enterPressed"
              @keydown.enter.shift.exact="newline"
              @blur="blur"
              @input="handleInput">
    </textarea>
    <textarea class="shadowtextarea"
              v-model="shadowcontent"
              ref="shadow"
              tabindex="0">
    </textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: {},
    white: {},
    minH: {},
    maxH: {},
    disabled: {type: Boolean, default: false},
    standardEnter: { type: Boolean, default: true },

  },
  data () {
    return {
      content: this.value,
      shadowcontent: this.value,
      inputHeight: '0',
      minHeight: 46,
      maxHeight: 100
    }
  },
  computed: {
    inputStyle () {
      return {
        'min-height': this.inputHeight
      }
    }
  },
  mounted () {
    if(this.minH) {
      this.minHeight = this.minH
    }
    if(this.maxH) {
      this.maxHeight = this.maxH
    }
    this.resize(this.value)
    setTimeout(() => {
      this.resize(this.value)
    },20)
  },
  watch: {
    value(val) {
      this.resize(val)
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
    newline() {
      this.$emit('input', `${this.value}`)
    },
    resize (val) {
      this.shadowcontent = val
      this.$nextTick(() => {
        this.inputHeight = `${Math.max(Math.min(this.$refs.shadow ? this.$refs.shadow.scrollHeight : this.minHeight, this.maxHeight), this.minHeight)}px`
        if(val == '') {
          this.inputHeight = this.minHeight+"px"
        }
      })

    },
    focus() {
      this.$emit('focus')
    },
    blur() {
      this.$emit('blur')
    },
    enterPressed() {
      if(this.standardEnter) {
        this.$emit('input', `${this.value}\n`)
      }
      this.$emit('enter')
    }
  }
}
</script>

<style scoped lang="scss">
textarea {
  resize: none;
  //overflow: hidden;
  height: 0;
  &.form-control {
    padding-top: 12px;
  }
  &.shadowtextarea {
    max-height: 0;
    pointer-events: none;
    opacity: 0;
    margin: 0;
    position: absolute;
    width: 100%;
  }
  &.white {
    background: #fff;

  }
}
</style>
