export default function instructorOrMethodist({ next, router }) {
    // console.log(router.app)
    if(router.app.$store && router.app.$store.state.logged && router.app.$store.state.user) {
        let role_id = parseInt(router.app.$store.state.user.primary_role_id)
        if([1,3,6].includes(role_id)) {
            return next()
        }
        if([2].includes(role_id)) {
            router.push('/student/home')
            return
        }
        if([4].includes(role_id)) {
            router.push('/school/home')
            return
        }
    }
    router.push('/login')
}
