<template>
    <div class="text-center">
        <div class="loader"></div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped lang="scss">
    .loader {
        width: 64px;
        height: 64px;
        z-index: 300;
        margin: 0 auto;

        &:after {
            content: " ";
            display: block;
            width: 60px;
            height: 60px;
            margin: 1px;
            border-radius: 50%;
            border: 4px solid $text-themed;
            border-color: $text-themed transparent $text-themed transparent;
            animation: lds-dual-ring 0.9s ease-in-out infinite;
        }
    }
    .nomargins {
        .loader {
            margin: 0 auto;
            width: 50px;
            height: 50px;
        }
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>